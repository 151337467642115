import * as React from 'react';

import {Image, Container, Row, Col} from 'react-bootstrap';
import {FormattedMessage} from 'gatsby-plugin-intl';

import iconRocket from '@images/home-about-icons/rocket.png';
import iconMoney from '@images/home-about-icons/money.png';
import iconNetwork from '@images/home-about-icons/network.png';
import iconUser from '@images/home-about-icons/user.png';
import rektorImg from '@images/home-rektor.jpg';
import LinkIcon from '../LinkIcon/LinkIcon';

export default function HomeAbout({numbers}) {
  return (
    <div className="home-about pt-xl">
      <Container>
        <div className="home-about__upper-section pb-lg">
          <Row>
            <Col md={5} lg={6}>
              <h1>
                Studenckie
                <br />
                Koła Naukowe
                <br />
                AGH
              </h1>
            </Col>
            <Col md={7} lg={6}>
              <div className="home-about__upper-section__info-boxes">
                <div>
                  <Image src={iconRocket} className="icon"></Image>
                  <h4>{numbers.projectsNumber}</h4>
                  <p>
                    <FormattedMessage id="index.grant" />
                  </p>
                </div>
                <div>
                  <Image src={iconMoney} className="icon"></Image>
                  <h4>{numbers.moneyAmount}</h4>
                  <p>
                    <FormattedMessage id="index.money" />
                  </p>
                </div>
                <div>
                  <Image src={iconNetwork} className="icon"></Image>
                  <h4>{numbers.knsNumber}</h4>
                  <p>
                    <FormattedMessage id="index.club" />
                  </p>
                  <div className="home-about__upper-section__info-boxes__links">
                    <LinkIcon
                      href={
                        numbers.linkGreen ||
                        'https://dss.agh.edu.pl/organizacje-studenckie/kola-naukowe-obszaru-zielonego'
                      }
                      color="#006a3a"
                      number={1}
                    />
                    <LinkIcon
                      href={
                        numbers.linkBlack ||
                        'https://dss.agh.edu.pl/organizacje-studenckie/kola-naukowe-obszaru-czarnego'
                      }
                      color="#2c2e35"
                      number={2}
                    />
                    <LinkIcon
                      href={
                        numbers.linkRed ||
                        'https://dss.agh.edu.pl/organizacje-studenckie/kola-naukowe-obszaru-czerwonego'
                      }
                      color="#d5232a"
                      number={3}
                    />
                  </div>
                </div>
                <div>
                  <Image src={iconUser} className="icon"></Image>
                  <h4>{numbers.studentsNumber}</h4>
                  <p>
                    <FormattedMessage id="index.students" />
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="home-about__lower-section pb-lg">
          <Row>
            <Col md={5}>
              <Image src={rektorImg} fluid rounded></Image>
            </Col>
            <Col md={{span: 5, offset: 1}} className="home-about__lower-section__rektor-box">
              <h4>
                <FormattedMessage id="index.rector" />
              </h4>
              <h1>
                <FormattedMessage id="index.title" />
                <br />
                <FormattedMessage id="index.name" />
              </h1>
              <p>
                <FormattedMessage id="index.skn" />
              </p>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}
