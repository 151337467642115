import {Link} from 'gatsby';
import React from 'react';
import './LinkIcon.sass';

export default function LinkIcon({color, className, href, number, ...props}) {
  const getBorderRadius = () => {
    if (number === 1) return '4px 0 0 4px';
    if (number === 3) return '0 4px 4px 0';
    return '0';
  };

  return (
    <Link
      style={{
        backgroundColor: color,
        borderRadius: getBorderRadius(),
      }}
      to={href}
      className={`link-icon ${className || ''}`}
      {...props}
      target="_blank"
      rel="noopener noreferrer"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 24 24"
        fill="none"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="lucide lucide-external-link"
      >
        <path d="M15 3h6v6" />
        <path d="M10 14 21 3" />
        <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6" />
      </svg>
    </Link>
  );
}
